const CONSTANT = {};
// CONSTANT.DEFAULT_FALLBACK_IMAGE =
//   'https://neosoft-technology.s3.ap-southeast-1.amazonaws.com/neosoft3_ecommerce/thumb.png';
CONSTANT.DEFAULT_FALLBACK_IMAGE =
  'https://images.unsplash.com/photo-1556228852-80b6e5eeff06?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=100';
CONSTANT.USERS_API_URL = '/api/v1/users/';
CONSTANT.DIRECT_BUY_PRODUCT = 'DIRECT_BUY_PRODUCT';
CONSTANT.PAYMENT_SELECTED = 'PAYMENT_SELECTED';
CONSTANT.APPOINTMENT_STATUS = [
  'book',
  'arrived',
  'consultation',
  'confirmed',
  'preparation',
  'process',
  'complete',
  'cancelled',
  'finished',
  'no_show',
];
CONSTANT.APPOINTMENT_CHANNEL_NAME = 'appointment';
export default CONSTANT;
