import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { toast } from 'react-toastify';

import activityService from './activityService';

const initialState = {
  activity: {
    data: [],
    error: false,
    loading: false,
    message: '',
  },
  activityValidate: {
    data: null,
    error: false,
    loading: false,
    message: '',
  },
};

export const getActivity = createAsyncThunk('activity/get', async (arg, thunkAPI) => {
  try {
    return await activityService.get(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const validateActivity = createAsyncThunk('activity/validate', async (arg, thunkAPI) => {
  try {
    return await activityService.validate(arg);
  } catch (error) {
    toast.error('Vouchers cannot be redeemed for this product');
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    reset: (state) => {
      set(state, 'activity.loading', false);
      set(state, 'activityValidate.loading', false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivity.pending, (state) => {
        set(state, 'activity.loading', true);
      })
      .addCase(getActivity.fulfilled, (state, action) => {
        const data = action.payload.map((item) => ({
          ...item,
        }));
        set(state, 'activity.loading', false);
        set(state, 'activity.data', data);
      })
      .addCase(getActivity.rejected, (state) => {
        set(state, 'activity.loading', false);
        set(state, 'activity.error', true);
      })
      .addCase(validateActivity.pending, (state) => {
        set(state, 'activityValidate.loading', true);
      })
      .addCase(validateActivity.fulfilled, (state, action) => {
        console.log('action.payloaddsds', action.payload);
        set(state, 'activityValidate.loading', false);
        set(state, 'activityValidate.data', action.payload);
      })
      .addCase(validateActivity.rejected, (state) => {
        set(state, 'activityValidate.loading', false);
        set(state, 'activityValidate.error', true);
      });
  },
});

export default activitySlice.reducer;
