/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import authService from './authService';

// @Get data from localstorage
const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  user: user || null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  isExist: false,
};

// @Todo Client ID user
export const clientId = createAsyncThunk('auth/clientid', async (thunkAPI) => {
  try {
    return await authService.getInstanceId();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// @Todo Check Patient
// @Todo This will check to legacy database in POS
export const checking = createAsyncThunk('auth/check-patient', async (arg, thunkAPI) => {
  try {
    return await authService.checking(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const resendOtp = createAsyncThunk('auth/resend-otp-patient', async (arg, thunkAPI) => {
  try {
    return await authService.checking(arg);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// @Todo Register User
export const register = createAsyncThunk('auth/register', async (arg, thunkAPI) => {
  try {
    return await authService.register(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// @Todo Register User
export const reRegister = createAsyncThunk('auth/reregister', async (arg, thunkAPI) => {
  try {
    return await authService.reRegister(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// @Todo Login User
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const checkExistingPatient = createAsyncThunk('auth/check-existing-patient', async (user, thunkAPI) => {
  try {
    return await authService.checkExistingPatient(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// @Todo Logout User
export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout();
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(reRegister.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reRegister.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(reRegister.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(checking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checking.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(checking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(resendOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendOtp.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(checkExistingPatient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkExistingPatient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isExist = action.payload.data.exist;
      })
      .addCase(checkExistingPatient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.isExist = true;
      });
  },
});

export const { reset } = authSlice.actions;

export default authSlice.reducer;
