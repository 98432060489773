import 'react-calendar/dist/Calendar.css';
import 'antd/dist/antd.css';
import './styles/index.scss';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import i18next from 'i18next';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { hydrate } from 'react-dom';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './app/App';
import store from './app/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import commonEn from './translations/en/common.json';
import commonId from './translations/id/common.json';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en',
  resources: {
    en: {
      common: commonEn, // 'common' is our custom namespace
    },
    id: {
      common: commonId,
    },
  },
});

const container = document.getElementById('root');

Sentry.init({
  dsn: 'https://f3dcb711090c4e36a85e910ca748bd76@o86107.ingest.sentry.io/6383512',
  integrations: [new BrowserTracing()],
  release: `neosoft-customer@${process.env.REACT_APP_VERSION}`,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const env = process.env.NODE_ENV;
const tagManagerArgs = {
  gtmId: 'GTM-5CPPQ5N',
  // the below changes GTM values based on whether dev or production
  auth: env === 'development' ? 'ZsxfNuPq4AFX2pdOGno0Og>' : '9KYqpiew1TNUampWKUPW5A',
  preview: env === 'development' ? 'env-6' : 'env-1',
};

TagManager.initialize(tagManagerArgs);

if (container.hasChildNodes()) {
  hydrate(
    <Router>
      <CookiesProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </Provider>
      </CookiesProvider>
    </Router>,
    container,
  );
} else {
  ReactDOM.createRoot(document.getElementById('root')).render(
    <Router>
      <CookiesProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </Provider>
      </CookiesProvider>
    </Router>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('firebase-messaging-sw.js')
    .then((registration) => {
      // eslint-disable-next-line no-console
      console.log('[SW]: SCOPE: ', registration.scope);
      return registration.scope;
    })
    .catch((err) => err);
}
