import PropTypes from 'prop-types';
import React from 'react';

function StatusAppointment({ statusAppointment }) {
  return (
    <p
      className={`font-body text-sm font-normal px-3 py-1 border text-desc text-semibold  rounded-lg inline w-auto ${
        statusAppointment === 'Book' ? 'bg-[#69AE8B] !text-white' : ''
      } ${statusAppointment === 'Finished' ? 'bg-[#69AEAD] !text-white' : ''} ${
        statusAppointment === 'Cancelled' ? 'bg-[#AE696A] !text-white' : ''
      }`}
    >
      {statusAppointment}
    </p>
  );
}

StatusAppointment.propType = {
  statusAppointment: PropTypes.any.isRequired,
};

export default React.memo(StatusAppointment);
