import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
    },
  },
};

const subtitleVariants = {
  hidden: {
    opacity: 0,
    y: 75,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
      duration: 0.4,
    },
  },
};

function Title({ isTitle, className, isSubtitle, titleClassName, subtitleClassName, useMotion = false }) {
  return (
    <div className={`w-full mb-4 ${className}`}>
      <motion.h3
        {...(useMotion
          ? {
              variants: titleVariants,
              initial: 'hidden',
              animate: 'visible',
            }
          : {})}
        className={`font-body block text-4xl font-bold text-black-gray mt-0 mb-1 ${titleClassName}`}
      >
        {isTitle}
      </motion.h3>
      {isSubtitle !== '' && (
        <motion.p
          {...(useMotion
            ? {
                variants: subtitleVariants,
                initial: 'hidden',
                animate: 'visible',
              }
            : {})}
          className={`font-body block text-xl font-medium text-black-gray-muted m-0 ${subtitleClassName}`}
        >
          {isSubtitle}
        </motion.p>
      )}
    </div>
  );
}

Title.propTypes = {
  isTitle: PropTypes.string.isRequired,
  isSubtitle: PropTypes.oneOfType([PropTypes.string]),
};

Title.defaultProps = {
  isSubtitle: '',
};

export default React.memo(Title);
