import React, { useState } from 'react';
import { HiOutlineBell, HiOutlineMenu, HiOutlineShoppingCart } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import useKeypress from '../../customHook/useKeyPress';
import { InputSearch } from '../index';
import SideDrawer from './SideDrawer';

function Header({ cartCounter, notificationCounter, isSearch }) {
  const history = useHistory();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  useKeypress('Escape', () => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  });
  return (
    <>
      <header className="fixed top-0 left-0 z-50 w-full h-16 bg-white-gray border-b border-gray-200 ">
        <div className="max-w-screen-sm mx-auto flex items-center h-16 px-6 justify-between">
          {/* <div className="flex items-center"> */}
          {/*  <button */}
          {/*    id="btn_hamburger" */}
          {/*    type="button" */}
          {/*    className="my-first-step relative bg-primary flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white" */}
          {/*    onClick={toggleDrawer} */}
          {/*    tour="step-3" */}
          {/*  > */}
          {/*    <HiOutlineMenu /> */}
          {/*  </button> */}
          {/* </div> */}
          {isSearch && (
            <div className="flex w-[40px]">
              <button
                type="button"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  // if (isUrl) {
                  //   history.replace(isUrl);
                  // } else {
                  //   history.go(-1);
                  // }
                  history.go(-1);
                }}
                className="absolute left-6 top-0 bottom-0 m-auto w-5 h-5 text-xl text-[#454547E5]"
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18 8.83223C18 8.27994 17.5523 7.83223 17 7.83223H5.88313C5.03426 7.83223 4.57145 6.84127 5.11632 6.19035L8.37863 2.2931C8.68955 1.92166 8.68955 1.38079 8.37863 1.00935L8.30053 0.916048C7.90083 0.438553 7.16662 0.438553 6.76692 0.916049L0.537303 8.35812C0.226378 8.72956 0.226379 9.27044 0.537304 9.64188L6.76692 17.084C7.16662 17.5614 7.90083 17.5614 8.30053 17.084L8.37863 16.9907C8.68955 16.6192 8.68955 16.0783 8.37863 15.7069L5.11632 11.8096C4.57145 11.1587 5.03425 10.1678 5.88313 10.1678H17C17.5523 10.1678 18 9.72005 18 9.16777V8.83223Z"
                    fill="#1d1d1f"
                    fillOpacity="0.9"
                  />
                </svg>
              </button>
            </div>
          )}
          <div className="w-full">
            <InputSearch />
          </div>
          <div className="flex items-center">
            <button
              type="button"
              className={`relative bg-primary  flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white ml-2 ${
                isSearch && 'hidden'
              }`}
              tour="step-1"
              onClick={() => {
                history.push('/notification');
              }}
            >
              <HiOutlineBell />
              {notificationCounter > 0 ? (
                <span className="absolute -top-0.5 -right-0.5 rounded-sm bg-[#FF7272] w-4 h-4 block text-center text-xs text-white tracking-tight">
                  {notificationCounter}
                </span>
              ) : null}
            </button>
            <button
              type="button"
              className="relative bg-primary flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white ml-2"
              tour="step-2"
              onClick={() => {
                history.push('/cart');
              }}
            >
              <HiOutlineShoppingCart />

              {cartCounter > 0 ? (
                <span className="absolute -top-0.5 -right-0.5 rounded-sm bg-[#FF7272] w-4 h-4 block text-center text-xs text-white tracking-tight">
                  {cartCounter}
                </span>
              ) : null}
            </button>
            <button
              id="btn_hamburger"
              type="button"
              className="my-first-step relative bg-primary flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white ml-2"
              onClick={toggleDrawer}
              tour="step-3"
            >
              <HiOutlineMenu />
            </button>
          </div>
        </div>
      </header>
      <SideDrawer isOpen={isDrawerOpen} isOnClose={toggleDrawer} />
    </>
  );
}

export default React.memo(Header);
