/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Drawer from 'react-modern-drawer';
import { useHistory } from 'react-router-dom';
import CONSTANT from '../../constant';
import OrderService from '../../features/order/orderService';
import { toast } from 'react-toastify';

function ItemTransaction({ order, isLast = false, onStatusUpdate }) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const { orderId, createdAt, totalAmount, status } = order;

  const date = new Date(createdAt);
  const options = {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric',  
    timeZone: 'Asia/Jakarta' 
  };  
  const formattedDate = new Intl.DateTimeFormat('id-ID', options).format(date);
  const customFormattedDate = formattedDate.replace('pukul', ' - ').trim();

  const handleH3Click = (arr) => {
    localStorage.setItem('ID_ORDER', arr);
    history.push(`/transaction/detail`);
  };

  const handleCencelOrder = async () => {
    if (status === 'PENDING') {
      try {
        let body = {
          status: 'CANCEL',
          orderId: order._id,
        };
        const result = await OrderService.updateStatus(body);
        toggleDrawer();
        if (onStatusUpdate) {
          onStatusUpdate();
        }
      } catch (error) {
        console.error('Gagal mengambil order:', error);
      }
    } else {
      toggleDrawer();
      toast.error('The status must be Pending');
    }
  };

  return (
    <>
      <div className={`relative block bg-white mb-4 ${isLast ? '' : 'border-b border-slate-200'}`}>
        <div className="flex">
          <span className="block p-3 pr-0">
            <LazyLoadImage
              src={CONSTANT.DEFAULT_FALLBACK_IMAGE}
              fallback={CONSTANT.DEFAULT_FALLBACK_IMAGE}
              className="w-20 min-w-[80px] h-20 object-cover object-center overflow-hidden rounded"
              alt="treatment"
            />
          </span>
          <div className="relative p-3">
            <h3
              className="font-display text-primary font-medium text-dark/90 whitespace-nowrap overflow-hidden text-ellipsis"
              onClick={() => handleH3Click(orderId)}
            >
              {orderId}
            </h3>
            <span className="font-body text-xs font-normal text-dark/60">{customFormattedDate}</span>
            <p className="font-body text-xs font-normal text-dark/90">{order.orderItems[0].product_treatment_name}</p>
          </div>
        </div>
        <div className="relative p-3 flex justify-between items-center border-t border-solid border-dark/20">
          <p className="font-body font-normal text-sm text-primary">
            <span className="block text-xs text-dark/60">Total Shopping</span>
            <CurrencyFormat
              value={totalAmount}
              displayType="text"
              decimalSeparator=","
              thousandSeparator="."
              prefix="Rp "
            />
          </p>
          <div className="flex">
            {status === 'COMPLETE' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-success bg-success/20 rounded items-center w-auto h-8 py-0 px-3">
                Goods Received
              </span>
            )}
            {status === 'PENDING' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-warning bg-warning/20 rounded items-center w-auto h-8 py-0 px-3">
                Waiting Payment
              </span>
            )}
            {status === 'PICKUP' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-warning bg-warning/20 rounded items-center w-auto h-8 py-0 px-3">
                In Packaging
              </span>
            )}
            {status === 'SHIPPING' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-warning bg-warning/20 rounded items-center w-auto h-8 py-0 px-3">
                In Delivery
              </span>
            )}
            {status === 'CANCEL' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-danger bg-danger/20 rounded items-center w-auto h-8 py-0 px-3">
                Order Canceled
              </span>
            )}
            {status === 'EXPIRED' && (
              <span
                style={{ backgroundColor: '#cccccc' }}
                className="font-body font-normal inline-flex text-xs justify-center text-white rounded items-center w-auto h-8 py-0 px-3"
              >
                Order Expired
              </span>
            )}
            {status === 'complain' && (
              <span className="font-body font-normal inline-flex text-xs justify-center text-danger bg-danger/20 rounded items-center w-auto h-8 py-0 px-3">
                Proses Komplain
              </span>
            )}
          </div>
          <button
            type="button"
            onClick={() => toggleDrawer()}
            className="w-8 h-8 inline-flex justify-center items-center outline-none bg-transparent ring-0 rounded ml-3 shadow-[0px_0px_1px_0px_#45454780]"
          >
            <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="4" height="4" fill="#454547" fillOpacity="0.9" />
              <rect y="6" width="4" height="4" fill="#454547" fillOpacity="0.9" />
              <rect y="12" width="4" height="4" fill="#454547" fillOpacity="0.9" />
            </svg>
          </button>
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
        zIndex={100000}
      >
        <div className="w-full relative pt-6 px-6">
          <h4 className="font-display font-semibold text-dark text-center text-xl mb-2">Cancel?</h4>
          <p className="font-body font-normal text-center text-dark/50 mt-0 mb-4">
            Are you sure you want to cancel this order?
          </p>
        </div>
        <div className="flex pt-2 px-6 pb-2">
          <button
            type="button"
            className="w-full tracking-wide block px-5 py-3.5 bg-primary hover:bg-[#8B69AE] focus:bg-[#8B69AE] active:bg-[#8B69AE]/90 font-display text-white font-normal text-base leading-tight rounded-[.625rem] focus:outline-none focus:ring-0 transition duration-150 ease-in-out mr-2"
            onClick={() => toggleDrawer()}
          >
            Close
          </button>
          <button
            type="button"
            className="w-full tracking-wide block px-5 py-3.5 bg-primary/20 hover:bg-[#8B69AE] focus:bg-[#8B69AE] active:bg-[#8B69AE]/90 font-display text-primary hover:text-white focus:text-white active:text-white font-normal text-base leading-tight rounded-[.625rem] focus:outline-none focus:ring-0 transition duration-150 ease-in-out mr-2"
            onClick={() => handleCencelOrder()}
          >
            <span className="underline">Yes, sure</span>
          </button>
        </div>
      </Drawer>
    </>
  );
}

ItemTransaction.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['PENDING', 'PAID', 'PICKUP', 'SHIPPING', 'CANCEL', 'EXPIRED', 'COMPLETE']).isRequired,
  }).isRequired,
  isLast: PropTypes.bool,
};

export default React.memo(ItemTransaction);
