/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEmpty, set } from 'lodash';

import appointmentService from './appointmentService';

const initialState = {
  point: {},
  doctors: [],
  rooms: [],
  schedule: {
    data: [],
    loading: false,
    error: false,
  },
  allAppointment: [],
  upcoming: {
    data: [],
    loading: false,
    error: false,
    loadingMore: false,
    pagination: {},
  },
  cancel: {
    data: [],
    loading: false,
    error: false,
    loadingMore: false,
    pagination: {},
  },
  history: {
    data: [],
    loading: false,
    error: false,
    loadingMore: false,
    pagination: {},
  },
  oneAppointnment: {
    data: [],
    loading: false,
    error: false,
  },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  selectRoom: null,
};

// @Todo Create Appointment User
export const createAppointment = createAsyncThunk('appointment/create', async (point, thunkAPI) => {
  try {
    return await appointmentService.createAppointment(point);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const editAppointment = createAsyncThunk('appointment/edit', async (point, thunkAPI) => {
  try {
    return await appointmentService.editAppointment(point);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const cancelAppointment = createAsyncThunk('appointment/cancel', async (point, thunkAPI) => {
  try {
    return await appointmentService.cancelAppointment(point);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

// @Get All Appointment By Upcoming
export const getAllAppointmentByUpcoming = createAsyncThunk(
  'appointment/upcoming',
  async (upcomingDataResponse, thunkAPI) => {
    try {
      return await appointmentService.getAllAppointmentByUpcoming(upcomingDataResponse);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getOneAppointment = createAsyncThunk('appointment/get-one', async (upcomingDataResponse, thunkAPI) => {
  try {
    return await appointmentService.getAllAppointmentByUpcoming(upcomingDataResponse);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getAllCancelAppointment = createAsyncThunk(
  'appointment/cancel',
  async (upcomingDataResponse, thunkAPI) => {
    try {
      return await appointmentService.getAllCancelAppointment(upcomingDataResponse);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  },
);

// @Get All Appointment By History
export const getAllAppointmentByHistory = createAsyncThunk(
  'appointment/history',
  async (historyDataResponse, thunkAPI) => {
    try {
      return await appointmentService.getAllAppointmentByHistory(historyDataResponse);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  },
);

// @Get All Doctors
export const getAllDoctors = createAsyncThunk('appointment/doctors', async (doctors, thunkAPI) => {
  try {
    return await appointmentService.getDoctors(doctors);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getAvailableSchedule = createAsyncThunk(
  'appointment/get-available-schedule',
  async (doctors, thunkAPI) => {
    try {
      return await appointmentService.getAvailableSchedule(doctors);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  },
);

// @Get All Rooms
export const getAllRooms = createAsyncThunk('appointment/rooms', async (rooms, thunkAPI) => {
  try {
    return await appointmentService.getRooms(rooms);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    resetAvailableSchedule: (state) => {
      state.schedule.data = [];
    },
    selectOutlet: (state, action) => {
      state.selectRoom = action.payload;
    },
    resetError: (state) => {
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAppointment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.point = action.payload;
      })
      .addCase(createAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editAppointment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editAppointment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.point = action.payload;
      })
      .addCase(editAppointment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllAppointmentByUpcoming.pending, (state, action) => {
        const queryPage = new URLSearchParams(action.meta.arg.query).get('page');
        if (parseInt(queryPage, 10) === 1) {
          state.upcoming.loading = !(state.upcoming.data.length > 0);
        }
        if (parseInt(queryPage, 10) > 1) {
          state.upcoming.loadingMore = true;
        }
      })
      .addCase(getAllAppointmentByUpcoming.fulfilled, (state, action) => {
        state.upcoming.loading = false;
        set(state, 'upcoming.loadingMore', false);
        let condition = [];
        if (!isEmpty(action.payload)) {
          condition =
            action.payload.current_page === 1
              ? action.payload.data
              : [...(state.upcoming.data || []), ...action.payload.data];
        }
        state.upcoming.data = condition;

        if (!isEmpty(action.payload)) {
          set(state, 'upcoming.pagination', {
            current_page: action.payload.current_page,
            first_page_url: action.payload.first_page_url,
            from: action.payload.from,
            last_page: action.payload.last_page,
            last_page_url: action.payload.last_page_url,
            next_page_url: action.payload.next_page_url,
            path: action.payload.path,
            per_page: action.payload.per_page,
            prev_page_url: action.payload.prev_page_url,
            to: action.payload.to,
            total: action.payload.total,
          });
        }
      })
      .addCase(getAllAppointmentByUpcoming.rejected, (state) => {
        state.upcoming.loading = false;
        state.upcoming.error = true;
      })
      .addCase(getAllCancelAppointment.pending, (state, action) => {
        const queryPage = new URLSearchParams(action.meta.arg.query).get('page');
        if (parseInt(queryPage, 10) === 1) {
          state.cancel.loading = !(state.cancel.data.length > 0);
        }

        if (parseInt(queryPage, 10) > 1) {
          state.cancel.loadingMore = true;
        }
      })
      .addCase(getAllCancelAppointment.fulfilled, (state, action) => {
        state.cancel.loading = false;
        set(state, 'cancel.loadingMore', false);

        let condition = [];
        if (!isEmpty(action.payload)) {
          condition =
            action.payload.current_page === 1
              ? action.payload.data
              : [...(state.cancel.data || []), ...action.payload.data];
        }

        state.cancel.data = condition;

        if (!isEmpty(action.payload)) {
          set(state, 'cancel.pagination', {
            current_page: action.payload.current_page,
            first_page_url: action.payload.first_page_url,
            from: action.payload.from,
            last_page: action.payload.last_page,
            last_page_url: action.payload.last_page_url,
            next_page_url: action.payload.next_page_url,
            path: action.payload.path,
            per_page: action.payload.per_page,
            prev_page_url: action.payload.prev_page_url,
            to: action.payload.to,
            total: action.payload.total,
          });
        }
      })
      .addCase(getAllCancelAppointment.rejected, (state) => {
        state.cancel.loading = false;
        state.cancel.error = true;
      })
      .addCase(getAllAppointmentByHistory.pending, (state, action) => {
        const queryPage = new URLSearchParams(action.meta.arg.query).get('page');
        if (parseInt(queryPage, 10) === 1) {
          state.history.loading = !(state.history.data.length > 0);
        }
        if (parseInt(queryPage, 10) > 1) {
          state.history.loadingMore = true;
        }
      })
      .addCase(getAllAppointmentByHistory.fulfilled, (state, action) => {
        state.history.loading = false;
        set(state, 'history.loadingMore', false);
        let condition = [];
        if (!isEmpty(action.payload)) {
          condition =
            action.payload.current_page === 1
              ? action.payload.data
              : [...(state.history.data || []), ...action.payload.data];
        }

        state.history.data = condition;

        if (!isEmpty(action.payload)) {
          set(state, 'history.pagination', {
            current_page: action.payload.current_page,
            first_page_url: action.payload.first_page_url,
            from: action.payload.from,
            last_page: action.payload.last_page,
            last_page_url: action.payload.last_page_url,
            next_page_url: action.payload.next_page_url,
            path: action.payload.path,
            per_page: action.payload.per_page,
            prev_page_url: action.payload.prev_page_url,
            to: action.payload.to,
            total: action.payload.total,
          });
        }
      })
      .addCase(getAllAppointmentByHistory.rejected, (state) => {
        state.history.loading = false;
        state.history.error = true;
      })

      .addCase(getOneAppointment.pending, (state) => {
        state.oneAppointnment.loading = true;
      })
      .addCase(getOneAppointment.fulfilled, (state, action) => {
        state.oneAppointnment.loading = false;
        state.oneAppointnment.data = action.payload;
      })
      .addCase(getOneAppointment.rejected, (state) => {
        state.oneAppointnment.loading = false;
        state.oneAppointnment.error = true;
      })
      .addCase(getAllDoctors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDoctors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.doctors = action.payload;
      })
      .addCase(getAllDoctors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAvailableSchedule.pending, (state) => {
        state.schedule.loading = true;
      })
      .addCase(getAvailableSchedule.fulfilled, (state, action) => {
        state.schedule.loading = false;
        state.schedule.data = action.payload;
      })
      .addCase(getAvailableSchedule.rejected, (state) => {
        state.schedule.loading = false;
      })
      .addCase(getAllRooms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllRooms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rooms = action.payload;
      })
      .addCase(getAllRooms.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, selectOutlet, resetAvailableSchedule, resetError } = appointmentSlice.actions;

export default appointmentSlice.reducer;
