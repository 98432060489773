import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function AppItem({ url, name, children, isComing }) {
  return (
    <Link
      to={url}
      className={cx('block', {
        'pointer-events-none': isComing,
      })}
    >
      <span
        className={cx(
          'relative flex items-center justify-center mx-auto w-14 h-14 bg-white rounded-lg mb-[0.625rem] shadow-[0px_0px_4px_1px_#0000001A]',
          {
            '!bg-[#4545471A] ': isComing,
          },
        )}
      >
        {children}
        {isComing && (
          <span className="absolute -bottom-2.5 text-white text-[0.5rem] bg-dark/90 w-10 py-0.5 rounded text-center">
            Segera
          </span>
        )}
      </span>
      <h6
        className={cx('font-body font-medium tracking-tight text-tiny text-center text-[#8B6AAE]', {
          'text-dark/50': isComing,
        })}
      >
        {name}
      </h6>
    </Link>
  );
}

AppItem.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isComing: PropTypes.oneOfType([PropTypes.bool]),
};

AppItem.defaultProps = {
  isComing: false,
};

export default React.memo(AppItem);
