import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

import wishlistService from './wishlistService';

const initialState = {
  create: {
    data: [],
    error: false,
    loading: false,
    message: '',
  },
  productExistInWishlist: {
    data: [],
    error: false,
    loading: false,
    message: '',
  },
};

export const createWishlist = createAsyncThunk('wishlist/create', async (arg, thunkAPI) => {
  try {
    const save = await wishlistService.create(arg);
    arg.callbackSuccess();
    return save;
  } catch (error) {
    arg.callbackError(error?.response?.data?.message);
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});
export const deleteWishlist = createAsyncThunk('wishlist/create', async (arg, thunkAPI) => {
  try {
    const deleteAction = await wishlistService.deleteWishlist(arg);
    arg.callbackSuccess();
    return deleteAction;
  } catch (error) {
    arg.callbackError(error?.response?.data?.message);
    return thunkAPI.rejectWithValue(error?.response?.data?.message);
  }
});

export const checkProductExistInWishlist = createAsyncThunk('wishlist/get', async (arg, thunkAPI) => {
  try {
    const response = await wishlistService.checkProductExistInWishlist(arg);
    if (arg?.callback) {
      arg.callback(response);
    }
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const wishlistSlice = createSlice({
  name: 'product',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createWishlist.pending, (state) => {
        set(state, 'create.loading', true);
      })
      .addCase(createWishlist.fulfilled, (state, action) => {
        set(state, 'create.loading', false);
        set(state, 'create.data', action.payload);
      })
      .addCase(createWishlist.rejected, (state) => {
        set(state, 'create.loading', false);
        set(state, 'create.error', true);
      })
      .addCase(checkProductExistInWishlist.pending, (state) => {
        set(state, 'productExistInWishlist.loading', true);
      })
      .addCase(checkProductExistInWishlist.fulfilled, (state, action) => {
        set(state, 'productExistInWishlist.data', action.payload);
        set(state, 'productExistInWishlist.loading', false);
      })
      .addCase(checkProductExistInWishlist.rejected, (state) => {
        set(state, 'productExistInWishlist.error', true);
        set(state, 'productExistInWishlist.loading', false);
      });
  },
});

export default wishlistSlice.reducer;
