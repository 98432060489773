/* eslint-disable */
import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const API_URL_ORDER = '/api/v1/orders';
const store = async (payload = {}) => {
  try {
    const response = await axiosApiInstance.post(
      `${process.env.REACT_APP_API_HOST}${API_URL_ORDER}`,
      {
        payload,
      },
      apiHeaderConfig(),
    );
    return response || null;
  } catch (err) {
    console.log(err);
  }
};

const get = async (orderId = "") => {
  try {
    const response = await axiosApiInstance.get(
      `${process.env.REACT_APP_API_HOST}${API_URL_ORDER}/${orderId}`,
      apiHeaderConfig(),
    );
    return response || null;
  } catch (err) {
    console.log(err);
  }
};

const list = async (param = {}) => {
  try {
    const response = await axiosApiInstance.get(
      `${process.env.REACT_APP_API_HOST}${API_URL_ORDER}?status=${param.status}`,
      apiHeaderConfig(),
    );
    return response || null;
  } catch (err) {
    console.log(err);
  }
};

const updateStatus = async (payload = {}) => {
  try {
    const response = await axiosApiInstance.post(
      `${process.env.REACT_APP_API_HOST}${API_URL_ORDER}/${payload.orderId}/update-status`,
      {
        payload,
      },
      apiHeaderConfig(),
    );
    return response || null;
  } catch (err) {
    console.log(err);
  }
};

const OrderService = {
  store,
  get,
  list,
  updateStatus
};
export default OrderService;
