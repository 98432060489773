/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
import 'swiper/scss';
import 'swiper/scss/free-mode';

import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { A11y, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import time from '../../mocks/time.json';
import asyncLocalStorage from '../../utils/storage';

const CalendarWrapper = styled((props) => <Swiper {...props} />)`
  display: flex;
  flex-direction: column-reverse;
`;

function SelectCalender({ type }) {
  const [calendar] = useState(time);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelection = (index) => {
    setSelectedItem((state) => (state === index ? null : index));

    const setTimeById = calendar.find((obj) => obj.id_time === index);
    const timeC = setTimeById.time_text;

    asyncLocalStorage.setItem(type === 'consultation' ? 'c_time' : 't_time', JSON.stringify(timeC)).then(() => {
      setSelectedItem((state) => (state === index ? null : index));
    });
  };

  return (
    <CalendarWrapper
      className="overflow-visible"
      modules={[A11y, FreeMode]}
      spaceBetween={16}
      freeMode
      slidesPerView={5}
      slideToClickedSlide
      centeredSlides
      centeredSlidesBounds
    >
      {calendar.map((item) => (
        <SwiperSlide key={item.id_time}>
          <button
            onClick={() => handleSelection(item.id_time)}
            type="button"
            className={cx(
              'h-14 relative flex items-center justify-center font-body text-xs font-bold text-[#A988CC] bg-white rounded-lg w-full shadow-sm overflow-hidden',
              {
                '!shadow-[0_0_0_2px_#A988CC]':
                  selectedItem === item.id_time ||
                  JSON.parse(
                    localStorage.getItem(type === 'consultation' ? 'c_time' : 't_time') ===
                      JSON.stringify(item.time_text),
                  ),
              },
            )}
          >
            {item.time_text}
          </button>
        </SwiperSlide>
      ))}
    </CalendarWrapper>
  );
}

SelectCalender.propTypes = {
  type: PropTypes.string.isRequired,
};

export default React.memo(SelectCalender);
