/* eslint-disable react/prop-types */
import 'react-modern-drawer/dist/index.css';

import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'react-modern-drawer';

// import { HiXCircle } from 'react-icons/hi';
import Subtitle from '../atoms/Subtitle';

function Filter({ isVisible, onChange }) {
  const [t] = useTranslation('common');
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const toggleSort = () => {
    setIsSortOpen((prevState) => !prevState);
  };

  const toggleCategory = () => {
    setIsCategoryOpen((prevState) => !prevState);
  };

  return (
    <>
      <div
        className={cx(
          'max-w-screen-sm mx-auto border-b border-solid border-[#f4f4f4] fixed top-14 left-0 right-0 z-50 w-full flex bg-white px-6 pt-3 pb-3 -translate-y-[61px] transition-transform duration-300 ease-in-out',
          {
            '!translate-y-[0]': !isVisible,
          },
        )}
      >
        <button
          type="button"
          className="w-9 h-9 rounded overflow-hidden shadow-sm bg-white flex items-center justify-center"
          onClick={toggleSort}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.5 15.6H5L10.25 21V3H8.5V15.6ZM13.75 5.7V21H15.5V8.4H19L13.75 3V5.7Z"
              fill="#463557"
              fillOpacity="0.75"
            />
          </svg>
        </button>
        <div className="relative flex-1 mx-2">
          <span className="absolute w-6 h-6 top-0 bottom-0 left-2 m-auto">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.6643 19.3307C15.8972 19.3307 19.3286 15.8989 19.3286 11.6654C19.3286 7.43182 15.8972 4 11.6643 4C7.43135 4 4 7.43182 4 11.6654C4 15.8989 7.43135 19.3307 11.6643 19.3307Z"
                fill="white"
                stroke="#463557"
                strokeOpacity="0.75"
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M14.2149 8.66584C13.8803 8.33039 13.4828 8.06436 13.0451 7.88306C12.6074 7.70175 12.1382 7.60875 11.6645 7.60938C10.6681 7.60938 9.76644 8.01294 9.11407 8.66584H14.2149ZM17.1747 17.1784L21 21.0044Z"
                fill="white"
              />
              <path
                d="M17.1747 17.1784L21 21.0044M14.2149 8.66584C13.8803 8.33039 13.4828 8.06436 13.0451 7.88306C12.6074 7.70175 12.1382 7.60875 11.6645 7.60938C10.6681 7.60938 9.76644 8.01294 9.11407 8.66584H14.2149Z"
                stroke="#463557"
                strokeOpacity="0.75"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <input
            type="text"
            className="w-full border-none shadow-sm rounded py-2 pl-10 pr-4 h-9 placeholder:text-[#46355733] focus:outline-none"
            placeholder={t('search')}
            // value={value}
            onChange={onChange}
          />
          {/* <button
            type="button"
            className="absolute z-20 w-6 h-6 bottom-0 top-0 right-3 flex items-center
            justify-center m-auto text-2xl"
            onClick={handleBack}
          >
            <HiXCircle className="text-[#463557]/30" />
          </button> */}
        </div>
        <button
          type="button"
          className="w-9 h-9 rounded overflow-hidden shadow-sm bg-white flex items-center justify-center"
          onClick={toggleCategory}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.5714 11.4287H17.1964M7.07141 3.42871C7.36978 3.42871 7.65593 3.54912 7.86691 3.76345C8.07788 3.97777 8.19641 4.26846 8.19641 4.57157V6.85728C8.19641 7.16039 8.07788 7.45108 7.86691 7.6654C7.65593 7.87973 7.36978 8.00014 7.07141 8.00014C6.77304 8.00014 6.48689 7.87973 6.27592 7.6654C6.06494 7.45108 5.94641 7.16039 5.94641 6.85728V4.57157C5.94641 4.26846 6.06494 3.97777 6.27592 3.76345C6.48689 3.54912 6.77304 3.42871 7.07141 3.42871V3.42871ZM20.5714 5.71443H8.19641H20.5714ZM5.94641 5.71443H2.57141H5.94641ZM7.07141 14.8573C7.36978 14.8573 7.65593 14.9777 7.86691 15.192C8.07788 15.4063 8.19641 15.697 8.19641 16.0001V18.2859C8.19641 18.589 8.07788 18.8797 7.86691 19.094C7.65593 19.3083 7.36978 19.4287 7.07141 19.4287C6.77304 19.4287 6.48689 19.3083 6.27592 19.094C6.06494 18.8797 5.94641 18.589 5.94641 18.2859V16.0001C5.94641 15.697 6.06494 15.4063 6.27592 15.192C6.48689 14.9777 6.77304 14.8573 7.07141 14.8573ZM20.5714 17.143H8.19641H20.5714ZM5.94641 17.143H2.57141H5.94641ZM16.0714 9.143C16.3698 9.143 16.6559 9.2634 16.8669 9.47773C17.0779 9.69206 17.1964 9.98275 17.1964 10.2859V12.5716C17.1964 12.8747 17.0779 13.1654 16.8669 13.3797C16.6559 13.594 16.3698 13.7144 16.0714 13.7144C15.773 13.7144 15.4869 13.594 15.2759 13.3797C15.0649 13.1654 14.9464 12.8747 14.9464 12.5716V10.2859C14.9464 9.98275 15.0649 9.69206 15.2759 9.47773C15.4869 9.2634 15.773 9.143 16.0714 9.143V9.143ZM14.9464 11.4287H2.57141H14.9464Z"
              stroke="#463557"
              strokeOpacity="0.75"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <Drawer
        open={isSortOpen}
        onClose={toggleSort}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
      >
        <div className="px-6 py-2">
          <Subtitle title="Urutan" body="Pilih berdasarkan urutan" />
        </div>
        <form className="sort-selection px-6">
          <label htmlFor="nameSort" className="block my-2">
            <input type="radio" id="nameSort" name="sort" />
            <span className="font-body text-sm text-[#463557]/75 font-medium block w-full">Nama</span>
          </label>
          <label htmlFor="timeSort" className="block my-2">
            <input type="radio" id="timeSort" name="sort" />
            <span className="font-body text-sm text-[#463557]/75 font-medium block w-full">Waktu</span>
          </label>
          <label htmlFor="locationSort" className="block my-2">
            <input type="radio" id="locationSort" name="sort" />
            <span className="font-body text-sm text-[#463557]/75 font-medium block w-full">Lokasi</span>
          </label>
        </form>
      </Drawer>
      <Drawer
        open={isCategoryOpen}
        onClose={toggleCategory}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
      >
        <div className="px-6 py-2">
          <Subtitle title="Urutan" body="Pilih berdasarkan urutan" />
        </div>
        <form className="sort-selection px-6">
          <label htmlFor="category1" className="block my-2">
            <input type="checkbox" id="category1" name="sort" />
            <span className="font-body text-sm text-[#463557]/75 font-medium block w-full">Category #1</span>
          </label>
          <label htmlFor="category2" className="block my-2">
            <input type="checkbox" id="category2" name="sort" />
            <span className="font-body text-sm text-[#463557]/75 font-medium block w-full">Category #2</span>
          </label>
          <label htmlFor="category3" className="block my-2">
            <input type="checkbox" id="category3" name="sort" />
            <span className="font-body text-sm text-[#463557]/75 font-medium block w-full">Category #3</span>
          </label>
          <label htmlFor="category4" className="block my-2">
            <input type="checkbox" id="category4" name="sort" />
            <span className="font-body text-sm text-[#463557]/75 font-medium block w-full">Category #4</span>
          </label>
          <label htmlFor="category5" className="block my-2">
            <input type="checkbox" id="category5" name="sort" />
            <span className="font-body text-sm text-[#463557]/75 font-medium block w-full">Category #5</span>
          </label>
          <button
            type="submit"
            className="font-body w-full block text-sm bg-primary hover:bg-[#8B69AE] focus:bg-[#8B69AE] active:bg-[#8B69AE]/90 py-3 px-6 rounded-lg text-white shadow-sm border-none mt-4 tracking-wider"
          >
            <b>Terapkan</b>
          </button>
        </form>
      </Drawer>
    </>
  );
}

export default React.memo(Filter);
