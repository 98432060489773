/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

function TextInput({
  label,
  fieldName,
  register,
  errors,
  placeholder,
  isRequired,
  maximLength,
  minimLength,
  pattern,
  isPhone,
  isDisabled,
  isReadonly,
  ...rest
}) {
  return (
    <label htmlFor={fieldName} className={`${!!isDisabled && 'opacity-50'}`}>
      <span
        className={`font-body text-xs font-normal text-black-gray block ${errors[fieldName] ? 'text-danger/50' : ''}`}
      >
        {label}
        {isRequired && label && (
          <sup id={`txt_error_${fieldName}`} className="text-danger -top-[0.25em]">
            {' '}
            *
          </sup>
        )}
      </span>
      <div className="relative">
        <input
          id={fieldName}
          name={fieldName}
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          className={`w-full px-0 py-1 bg-transparent border-l-0 border-t-0 border-r-0 border-b-[1.5px] ring-0 outline-none focus:ring-0 border-[#D8D8D8] focus:border-primary ${
            (errors[fieldName] ? '!border-danger' : '', isPhone ? '!pl-11' : '')
          }`}
          disabled={!!isDisabled}
          readOnly={!!isReadonly}
          {...register(fieldName, {
            required: {
              value: isRequired,
              message: 'Harus diisi!',
            },
            maxLength: {
              value: maximLength,
              message: isPhone ? `Maksimal nomor handphone ${maximLength}` : `Maksimal karakter ${maximLength}`,
            },
            minLength: {
              value: minimLength,
              message: isPhone ? `Minimal nomor handphone ${maximLength}` : `Minimal karakter ${maximLength}`,
            },
            pattern: {
              value: pattern,
              message: 'Data tidak valid!',
            },
          })}
          {...rest}
        />
        {errors[fieldName] && (
          <HiOutlineExclamationCircle className="absolute top-2 right-0 text-sm text-danger w-4 h-4" />
        )}
        {isPhone && <em className="absolute bottom-[0.325rem] left-0 not-italic opacity-70">(+62)</em>}
      </div>
      {errors[fieldName] && (
        <small id={`txt_error_${fieldName}`} className="text-danger text-tiny block mt-2">
          {errors[fieldName].message}
        </small>
      )}
    </label>
  );
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  register: PropTypes.any.isRequired,
  errors: PropTypes.object.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string]),
  isRequired: PropTypes.oneOfType([PropTypes.bool]),
  maximLength: PropTypes.oneOfType([PropTypes.number]),
  minimLength: PropTypes.oneOfType([PropTypes.number]),
  pattern: PropTypes.oneOfType([PropTypes.string]),
  isPhone: PropTypes.oneOfType([PropTypes.bool]),
  isDisabled: PropTypes.oneOfType([PropTypes.bool]),
  isReadonly: PropTypes.oneOfType([PropTypes.bool]),
};

TextInput.defaultProps = {
  placeholder: '',
  isRequired: false,
  maximLength: null,
  minimLength: null,
  pattern: '',
  isPhone: false,
  isDisabled: false,
  isReadonly: false,
};

export default React.memo(TextInput);
