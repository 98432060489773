/* eslint-disable import/no-unresolved */
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import PropTypes from 'prop-types';
import React from 'react';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

function CarouselProductDetail({ children, isNavigation, isPagination, slidesPerView = 2.5, spaceBetween = 16 }) {
  const pagination = {
    clickable: true,
  };

  return (
    <Swiper
      className="overflow-visible"
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      navigation={isNavigation}
      pagination={isPagination ? pagination : ''}
    >
      {children}
    </Swiper>
  );
}

CarouselProductDetail.propTypes = {
  children: PropTypes.node.isRequired,
  isNavigation: PropTypes.oneOfType([PropTypes.bool]),
  isPagination: PropTypes.oneOfType([PropTypes.bool]),
};

CarouselProductDetail.defaultProps = {
  isNavigation: false,
  isPagination: true,
};

export default React.memo(CarouselProductDetail);
