import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FaStar } from 'react-icons/fa';

function Rating({ score, size }) {
  return (
    <span className="flex items-center">
      <FaStar
        className={cx('text-[#DCDC25]', {
          'text-xl': size === 'base',
          'text-sm': size === 'sm',
        })}
      />
      <em
        className={cx('font-sans not-italic border-b border-black leading-none', {
          'text-sm ml-2': size === 'base',
          'text-xs ml-1': size === 'sm',
        })}
      >
        {score}
      </em>
    </span>
  );
}

Rating.propTypes = {
  score: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'base']),
};

Rating.defaultProps = {
  size: 'base',
};

export default React.memo(Rating);
