import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useSelector } from 'react-redux';

import CONSTANT from '../../constant';

export const marketingActivities = [
  {
    activity_name: 'Discount 50%',
    discount_type: 'nominal',
    discount_value: 15000,
    min: 10000,
    max: 15000,
    items: ['P-21070001'],
    packages: [],
  },
  {
    activity_name: 'Discount 20%',
    discount_type: 'percentage',
    discount_value: 20,
    min: 10000,
    max: 20000,
    items: ['P-21010001'],
    packages: [],
  },
  {
    activity_name: 'Discount 5%',
    discount_type: 'percentage',
    discount_value: 5,
    min: 0,
    max: 20000,
    items: ['P-21010003'],
    packages: [],
  },
  {
    activity_name: 'Discount 11%',
    discount_type: 'percentage',
    discount_value: 11,
    min: 0,
    max: 20000,
    items: ['P-21010002'],
    packages: [],
  },
  {
    activity_name: 'Discount 67%',
    discount_type: 'percentage',
    discount_value: 0,
    min: 0,
    max: 15000,
    items: ['P-23070003'],
    packages: [],
    bogof: {
      buy: 1,
      free: 1,
    },
  },
];

export const checkAvailibilityBogof = (item, qty = 0, activities = []) => {
  const marketingActivity = activities.find(
    (activity) =>
      activity.items.includes(item.product_treatment_code) || activity.packages.includes(item.product_treatment_code),
  );

  if (marketingActivity?.discount_code === 'REDEEM_REQUIRED') {
    return undefined;
  }

  if (marketingActivity?.bogof) {
    if (qty > marketingActivity?.bogof.buy) return undefined;

    if (marketingActivity?.bogof.buy === qty) {
      return (
        <>
          You get{' '}
          <strong>
            Buy {marketingActivity.bogof.buy} Get {marketingActivity.bogof.count_free} Free
          </strong>
        </>
      );
    }
    const more = marketingActivity.bogof.buy - qty;
    return (
      <>
        Add {more} more qty to get{' '}
        <strong>
          Buy {marketingActivity.bogof.buy} Get {marketingActivity.bogof.count_free} Free
        </strong>
      </>
    );
  }

  return undefined;
};
export const checkAvailibilityDiscount = (item, activities = [], state = 'lists') => {
  const marketingActivity = activities.find(
    (activity) =>
      activity.items.includes(item.product_treatment_code) || activity.packages.includes(item.product_treatment_code),
  );

  const isValid = state === 'validate' ? true : marketingActivity?.discount_code !== 'REDEEM_REQUIRED';

  if (marketingActivity && marketingActivity?.bogof?.buy && isValid) {
    return {
      ...marketingActivity,
      discount_value: 0,
      price_discount: item.price_ecommerce,
    };
  }

  if (marketingActivity && marketingActivity?.discount_value > 0 && isValid) {
    let priceDiscount = 0;
    let discountValue = marketingActivity.discount_value;
    if (marketingActivity.discount_type === 'percentage') {
      const discountValueNominal = Math.ceil(item.price_ecommerce * (marketingActivity.discount_value / 100));
      priceDiscount = item.price_ecommerce - Math.ceil(item.price_ecommerce * (marketingActivity.discount_value / 100));

      // jika melebihi batas
      // berlaku khusus percentage
      if (discountValueNominal > marketingActivity.max) {
        priceDiscount = item.price_ecommerce - marketingActivity.max;
        discountValue = Math.ceil(((item.price_ecommerce - priceDiscount) * 100) / item.price_ecommerce);
      }

      console.log({
        discountValue,
        priceDiscount: Math.ceil(item.price_ecommerce * (marketingActivity.discount_value / 100)),
      });
    } else {
      priceDiscount = item.price_ecommerce - marketingActivity.discount_value;

      discountValue = Math.ceil((marketingActivity.discount_value * 100) / item.price_ecommerce);
      // jika discount melebihi batas jadikan 0
      if (priceDiscount < 0) {
        priceDiscount = 0;
        discountValue = 100;
      }
    }

    // jika masuk ke min purchase per item
    // berbeda dengan yang di cart ya
    if (item.price_ecommerce >= marketingActivity.min) {
      return {
        ...marketingActivity,
        discount_value: discountValue,
        price_discount: priceDiscount,
      };
    }
  }
  return undefined;
};

function ItemProduct({ item, setBottomSheetProduct, setProductPopUpData }) {
  // eslint-disable-next-line no-unused-vars
  const activityState = useSelector((state) => state.activity);

  const [activity, setActivity] = useState(null);

  useEffect(() => {
    const { data } = activityState.activity || [];
    setActivity(checkAvailibilityDiscount(item, data));
  }, [activityState, item]);

  return (
    <div
      aria-hidden
      onClick={() => {
        setProductPopUpData(item);
        setBottomSheetProduct(true);
      }}
      key={item.id}
      className="relative bg-white block w-full border rounded-lg overflow-hidden my-2 min-h-[250px]"
    >
      {item?.images?.length > 0 ? (
        <Image
          preview={false}
          src={`${item?.images[0]}` || CONSTANT.DEFAULT_FALLBACK_IMAGE}
          fallback={CONSTANT.DEFAULT_FALLBACK_IMAGE}
          className="w-full h-[135px]  md:w-[240px]  object-cover"
          alt={item.product_treatment_code}
        />
      ) : (
        <Image
          preview={false}
          src={`${item.url_img}` || CONSTANT.DEFAULT_FALLBACK_IMAGE}
          fallback={CONSTANT.DEFAULT_FALLBACK_IMAGE}
          className="w-full h-[135px]  md:w-[240px]  object-cover"
          alt={item.product_treatment_code}
        />
      )}
      {item.kind === 'package' && (
        <sup className="absolute top-0 right-0 rounded-bl  p-2 bg-[#AE696A] text-white font-semibold">Package</sup>
      )}
      {item.kind === 'treatment' && (
        <sup className="absolute top-0 right-0 rounded-bl  p-2 bg-[#8CAE69] text-white font-semibold">Treatment</sup>
      )}

      {item.kind === 'product' && (
        <sup className="absolute top-0 right-0 rounded-bl  p-2 bg-[#69AEAD] text-white font-semibold">Product</sup>
      )}

      <div className="relative p-2">
        <p className="font-body text-sm font-normal text-[#4A4A4A] mb-1 line-clamp-2 min-h-[40px]">
          {item.product_treatment_name}
        </p>
        <span className="block font-body text-sm text-[#8B6AAE] font-bold">
          {activity ? (
            <CurrencyFormat
              value={activity.price_discount}
              displayType="text"
              decimalSeparator=","
              thousandSeparator="."
              prefix="Rp "
            />
          ) : (
            <CurrencyFormat
              value={item.price_ecommerce}
              displayType="text"
              decimalSeparator=","
              thousandSeparator="."
              prefix="Rp "
            />
          )}
        </span>
        {activity ? (
          <>
            <div className="mt-1" />
            <small>
              <p className="font-normal flex items-center gap-x-1">
                <div className="rounded bg-danger/20 p-1 text-danger font-bold">{activity.discount_value}%</div>
                <s className="text-black-gray-muted">
                  <CurrencyFormat
                    value={item.price_ecommerce}
                    displayType="text"
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix="Rp "
                  />
                </s>
              </p>
            </small>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default React.memo(ItemProduct);
