import 'react-modern-drawer/dist/index.css';

import { Upload } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import {
  // HiOutlineBell,
  HiOutlineCash,
  HiOutlineCheckCircle,
  HiOutlineClock,
  HiOutlineCog,
  HiOutlineCreditCard,
  HiOutlineCurrencyDollar,
  HiOutlineGift,
  HiOutlineHeart,
  HiOutlineHome,
  HiOutlineLogout,
  HiOutlineQrcode,
  HiOutlineSave,
  HiOutlineShoppingCart,
  HiOutlineUser,
  HiOutlineX,
} from 'react-icons/hi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Drawer from 'react-modern-drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { logout } from '../../features/auth/authSlice';
import { resetTreatmentBalance } from '../../features/user/userSlice';
import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';
import Subtitle from '../atoms/Subtitle';

function SideDrawer({ isOpen, isOnClose }) {
  const dispatch = useDispatch();
  const [t] = useTranslation('common');

  const personal = JSON.parse(localStorage.getItem('personal'));

  const { user, isSuccess } = useSelector((state) => state.auth);

  // eslint-disable-next-line no-unused-vars
  const [token, setToken, removeToken] = useCookies(['accessToken']);

  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const getPersonal = useCallback(async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token.accessToken || null}`,
      },
    };

    const response = await axiosApiInstance.get(`${process.env.REACT_APP_API_HOST}/api/v1/users/me`, config);

    if (response.data) {
      localStorage.setItem('personal', JSON.stringify(response.data));
      setImageUrl(response?.data?.data?.avatar);
    }

    return response.data;
  }, [token]);

  useEffect(() => {
    if (isSuccess || user) {
      getPersonal();
    }
  }, [user, isSuccess, getPersonal]);

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      dispatch(resetTreatmentBalance());
      removeToken('accessToken');
    });
  };

  const handleChangeAvatar = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      const { response } = info.file;
      setImageUrl(response?.data);
      setLoading(false);
    }
  };

  return (
    <Drawer
      open={isOpen}
      onClose={isOnClose}
      direction="left"
      className="!w-full !overflow-y-auto"
      overlayOpacity={1}
      overlayColor="#ffffff40"
    >
      <>
        <div className="w-full flex flex-col pt-8 justify-center items-center bg-primary">
          <button
            id="btn_close_hamburger"
            type="button"
            className="absolute top-3 left-6 bg-[#46355759] flex items-center justify-center w-10 h-10 rounded-lg text-xl text-white"
            onClick={isOnClose}
          >
            <HiOutlineX />
          </button>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <button type="button" className="block mb-6 no-underline">
            {/* <Avatar className="w-32 h-32 overflow-hidden rounded-full border border-[#A988CC]/75  mx-auto flex items-center bg-primary/70"> */}
            {/*  */}
            {/* </Avatar> */}
            <Upload
              accept="image/png, image/jpg"
              name="avatar"
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              action={`${process.env.REACT_APP_API_HOST}/api/v1/users/upload-avatar`}
              onChange={handleChangeAvatar}
              {...apiHeaderConfig()}
            >
              {loading ? (
                <div className="w-32 h-32 overflow-hidden rounded-full border border-[#A988CC]/75 justify-center  mx-auto flex items-center bg-primary/70">
                  <span className="bg-ellipses h-3 w-16 block mx-auto my-[0.325rem] bg-contain bg-no-repeat bg-center" />
                </div>
              ) : (
                <div>
                  {imageUrl ? (
                    // <img
                    //   src={imageUrl}
                    //   alt="avatar"
                    //   style={{
                    //     width: '100%',
                    //   }}
                    // />
                    <LazyLoadImage
                      src={`${imageUrl}`}
                      className="w-32 h-32 overflow-hidden rounded-full border border-[#A988CC]/75  mx-auto flex items-center bg-primary/70"
                      alt="user"
                    />
                  ) : (
                    <div className="w-32 h-32 overflow-hidden rounded-full border border-[#A988CC]/75 justify-center  mx-auto flex items-center bg-primary/70">
                      <HiOutlineUser className="w-20 h-20 text-slate-50" />
                    </div>
                  )}
                </div>
              )}
            </Upload>
            {/* <LazyLoadImage */}
            {/*  src={`${process.env.PUBLIC_URL}/assets/images/user.jpg`} */}
            {/*  className="w-32 h-32 overflow-hidden rounded-full border border-[#A988CC]/75 block mx-auto" */}
            {/*  alt="user" */}
            {/* /> */}
          </button>
          <div className="w-full pt-4 pb-8 bg-secondary/40 rounded-t-2xl">
            <p className="font-body text-base text-white font-medium block m-0">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link to="/qrcode" className="flex items-center justify-center no-underline">
                <HiOutlineQrcode className="text-2xl mr-1 block" />
                {personal ? personal.data.name : ''}
              </Link>
            </p>
          </div>
        </div>
        <div className="relative z-10 pt-8 px-6 bg-white rounded-2xl -mt-4">
          <Subtitle title={t('your_activity')} body={t('check_your_activity')} />
          <div className="block mb-6 your-activity">
            {/* <Link */}
            {/*  id="nav_notification" */}
            {/*  to="/notification" */}
            {/*  className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item" */}
            {/* > */}
            {/*  <HiOutlineBell className="text-lg mr-2" /> */}
            {/*  <span className="block tracking-wider">{t('notification')}</span> */}
            {/* </Link> */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              id="nav_point"
              to="#"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item justify-between"
            >
              <div className="flex">
                <HiOutlineCurrencyDollar className="text-lg mr-2" />
                <span className="block tracking-wider">{t('point')}</span>
              </div>
              <div className="text-[#8B69AE] font-bold">{personal ? personal.data.detail.point : 0}</div>
            </Link>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              id="nav_deposit"
              to="#"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item justify-between"
            >
              <div className="flex">
                <HiOutlineSave className="text-lg mr-2" />
                <span className="block tracking-wider">{t('deposit')}</span>
              </div>
              <div className="text-[#8B69AE] font-bold">{personal ? personal.data.detail.deposit : 0}</div>
            </Link>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              id="nav_credit_voucher"
              to="#"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item justify-between"
            >
              <div className="flex">
                <HiOutlineCreditCard className="text-lg mr-2" />
                <span className="block tracking-wider">{t('credit_voucher')}</span>
              </div>
              <div className="text-[#8B69AE] font-bold">{personal ? personal.data.detail.credit_voucher : 0}</div>
            </Link>
            {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' ? (
              <Link
                id="nav_cart"
                to="/cart"
                className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
              >
                <HiOutlineShoppingCart className="text-lg mr-2" />
                <span className="block tracking-wider">{t('cart')}</span>
              </Link>
            ) : null}

            <Link
              id="nav_treatment_balance"
              to="/treatment-balance"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
            >
              <HiOutlineCash className="text-lg mr-2" />
              <span className="block tracking-wider">{t('treatment_balance')}</span>
            </Link>

            <Link
              id="nav_treatment_transaction"
              to="/treatment-transaction"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
            >
              <HiOutlineCheckCircle className="text-lg mr-2" />
              <span className="block tracking-wider">{t('treatment_transaction')}</span>
            </Link>
            <Link
              id="nav_purchase_history"
              to="/purchase-history"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
            >
              <HiOutlineClock className="text-lg mr-2" />
              <span className="block tracking-wider">{t('purchase_history')}</span>
            </Link>
            {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' ? (
              <Link
                id="nav_ecommerce_transaction"
                to="/transaction"
                className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
              >
                <HiOutlineGift className="text-lg mr-2" />
                <span className="block tracking-wider">{t('transaction')}</span>
              </Link>
            ) : null}
            {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' ? (
              <Link
                id="nav_wishlist"
                to="/wishlist"
                className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-activity-item"
              >
                <HiOutlineHeart className="text-lg mr-2" />
                <span className="block tracking-wider">{t('wishlist')}</span>
              </Link>
            ) : null}
          </div>
          <Subtitle title={t('setting')} body={t('change_app_setting')} />
          <div className="block mb-4 your-setting">
            <Link
              to="/profile/account"
              className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-setting-item"
            >
              <HiOutlineCog className="text-lg mr-2" />
              <span className="block tracking-wider">{t('account')}</span>
            </Link>
            {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' ? (
              <Link
                to="/shipping/address"
                className="font-body flex py-3 text-sm items-center border-b border-[#efedf1] text-secondary your-setting-item"
              >
                <HiOutlineHome className="text-lg mr-2" />
                <span className="block tracking-wider">{t('address')}</span>
              </Link>
            ) : null}

            <button
              data-cy="btn_logout"
              type="button"
              onClick={handleLogout}
              className="font-body flex py-3 text-sm items-center border-b-0 border-[#efedf1] text-secondary your-setting-item"
            >
              <HiOutlineLogout className="text-lg mr-2" />
              <span className="block tracking-wider">{t('signout')}</span>
            </button>
          </div>
        </div>
      </>
    </Drawer>
  );
}

SideDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOnClose: PropTypes.func.isRequired,
};

export default React.memo(SideDrawer);
