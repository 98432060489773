import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const getArea = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/shipper/areas${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};
const getProvince = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/shipper/provinces${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};
const getCity = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/shipper/cities${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};
const getDistrict = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/shipper/districts${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};
const getRates = async (arg = {}) => {
  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}/api/v1/shipper/rates`,
    arg.payload,
    apiHeaderConfig(),
  );
  return response.data.data;
};
const ShippingService = {
  getArea,
  getProvince,
  getCity,
  getDistrict,
  getRates,
};
export default ShippingService;
