import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';

function Ratings({ score, size }) {
  return (
    <span className="flex items-center">
      <div className="flex">
        {score === '0.5' && (
          <>
            <FaStarHalfAlt
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
        {score === '1.0' && (
          <>
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
        {score === '1.5' && (
          <>
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStarHalfAlt
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
        {score === '2.0' && (
          <>
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
        {score === '2.5' && (
          <>
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStarHalfAlt
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
        {score === '3.0' && (
          <>
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
        {score === '3.5' && (
          <>
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStarHalfAlt
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
        {score === '4.0' && (
          <>
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaRegStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
        {score === '4.5' && (
          <>
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStarHalfAlt
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
        {score === '5.0' && (
          <>
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
            <FaStar
              className={cx('text-[#DCDC25] mr-0.5', {
                'text-lg': size === 'base',
                'text-xs': size === 'sm',
              })}
            />
          </>
        )}
      </div>
      <em
        className={cx('font-sans not-italic border-b border-black leading-none', {
          'text-sm ml-1.5': size === 'base',
          'text-xs ml-0.5': size === 'sm',
        })}
      >
        {score}
      </em>
    </span>
  );
}

Ratings.propTypes = {
  score: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'base']),
};

Ratings.defaultProps = {
  size: 'base',
};

export default React.memo(Ratings);
