import { toast } from 'react-toastify';

import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const API_URL_ACTIVITY = '/api/v1/activity';
const get = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${API_URL_ACTIVITY}${query}`,
    apiHeaderConfig(),
  );
  return response.data.data.data || [];
};

const API_URL_ACTIVITY_VALIDATE = '/api/v1/activity';
const validate = async (payload = {}) => {
  let data = null;
  try {
    const response = await axiosApiInstance.post(
      `${process.env.REACT_APP_API_HOST}${API_URL_ACTIVITY_VALIDATE}`,
      {
        ...payload,
        discount_code: payload?.discount_code || '',
        items: payload?.items || [''],
      },
      apiHeaderConfig(),
    );

    data = response?.data?.data?.data?.length > 0 ? response.data.data.data[0] : null;

    if (data) {
      toast.success('Voucher redeem was successful');
    } else {
      toast.error('Vouchers cannot be redeemed for this product');
    }
  } catch (err) {
    toast.error('Vouchers cannot be redeemed for this product');
  }

  return data || null;
};

const ActivityService = {
  get,
  validate,
};
export default ActivityService;
