import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import NeosoftLogo from '../../assets/neosoft_dark.svg';

function Logo() {
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (localStorage.getItem('clinic')) {
      const parseClinic = JSON.parse(localStorage.getItem('clinic'));
      setImage(`${parseClinic?.data?.posUrl}`);
    }
  }, [localStorage.getItem('clinic')]);

  return <LazyLoadImage src={image} className="w-full block" alt="neosoft logo" />;
}

export default React.memo(Logo);
