import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const get = async (arg = {}) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/baskets${arg?.query || ''}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const getCounter = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/baskets/count-cart${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const getTotalPrice = async (arg = {}) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}/api/v1/baskets/count-price${arg?.query || ''}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const deleteCart = async (id = '') => {
  const response = await axiosApiInstance.delete(
    `${process.env.REACT_APP_API_HOST}/api/v1/baskets/${id}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};
const update = async (id, payload) => {
  const response = await axiosApiInstance.put(
    `${process.env.REACT_APP_API_HOST}/api/v1/baskets/${id}`,
    payload,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const create = async (arg) => {
  const response = await axiosApiInstance.post(
    `${process.env.REACT_APP_API_HOST}/api/v1/baskets`,
    arg.payload,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const CartService = {
  getTotalPrice,
  getCounter,
  get,
  deleteCart,
  update,
  create,
};
export default CartService;
