/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isUndefined, set } from 'lodash';

import productService from './productService';

const initialState = {
  category: {
    data: [],
    error: false,
    loading: false,
    message: '',
  },
  product: {
    data: [],
    pagination: {},
    error: false,
    loading: false,
    loadingMore: false,
    message: '',
  },
  productBestDeal: {
    data: [],
    error: false,
    loading: false,
    message: '',
  },
  productArrival: {
    data: [],
    error: false,
    loading: false,
    message: '',
  },
  productTrending: {
    data: [],
    error: false,
    loading: false,
    message: '',
  },
};

export const getProductCategory = createAsyncThunk('product/get-category', async (arg, thunkAPI) => {
  try {
    return await productService.getCategories(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getProductStock = createAsyncThunk('product/get-stock', async (arg, thunkAPI) => {
  try {
    return await productService.getStockProductService(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getProduct = createAsyncThunk('product/get', async (arg, thunkAPI) => {
  try {
    return await productService.get(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getProductArrival = createAsyncThunk('product/get-arrival', async (arg, thunkAPI) => {
  try {
    return await productService.get(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getProductBestDeal = createAsyncThunk('product/get-best-deal', async (arg, thunkAPI) => {
  try {
    return await productService.get(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const getProductTrending = createAsyncThunk('product/get-trending', async (arg, thunkAPI) => {
  try {
    return await productService.get(arg);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message && error.response.data.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    reset: (state) => {
      set(state, 'product.loading', false);
      set(state, 'productBestDeal.loading', false);
      set(state, 'productArrival.loading', false);
      set(state, 'productTrending.loading', false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state, action) => {
        if (isUndefined(action.meta.arg)) {
          set(state, 'product.loading', true);
        } else {
          set(state, 'product.loadingMore', true);
        }
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        set(state, 'product.loading', false);
        set(state, 'product.loadingMore', false);
        set(
          state,
          'product.data',
          action.payload.current_page === 1
            ? action.payload.data
            : [...(state.product.data || []), ...action.payload.data],
        );
        set(state, 'product.pagination', {
          current_page: action.payload.current_page,
          first_page_url: action.payload.first_page_url,
          from: action.payload.from,
          last_page: action.payload.last_page,
          last_page_url: action.payload.last_page_url,
          next_page_url: action.payload.next_page_url,
          path: action.payload.path,
          per_page: action.payload.per_page,
          prev_page_url: action.payload.prev_page_url,
          to: action.payload.to,
          total: action.payload.total,
        });
      })
      .addCase(getProduct.rejected, (state) => {
        set(state, 'product.loading', false);
        set(state, 'product.loadingMore', false);
        set(state, 'product.error', true);
      })

      .addCase(getProductArrival.pending, (state) => {
        set(state, 'product.productArrival', true);
      })
      .addCase(getProductArrival.fulfilled, (state, action) => {
        set(state, 'productArrival.data', action.payload);
        set(state, 'productArrival.loading', false);
      })
      .addCase(getProductArrival.rejected, (state) => {
        set(state, 'productArrival.error', true);
        set(state, 'productArrival.loading', false);
      })
      .addCase(getProductBestDeal.pending, (state) => {
        set(state, 'productBestDeal.loading', true);
      })
      .addCase(getProductBestDeal.fulfilled, (state, action) => {
        set(state, 'productBestDeal.data', action.payload);
        set(state, 'productBestDeal.loading', false);
      })
      .addCase(getProductBestDeal.rejected, (state) => {
        set(state, 'productBestDeal.error', true);
        set(state, 'productBestDeal.loading', false);
      })
      .addCase(getProductTrending.pending, (state) => {
        set(state, 'productTrending.loading', true);
      })
      .addCase(getProductTrending.fulfilled, (state, action) => {
        set(state, 'productTrending.loading', false);
        set(state, 'productTrending.data', action.payload);
      })
      .addCase(getProductTrending.rejected, (state) => {
        set(state, 'productTrending.loading', false);
        set(state, 'productTrending.error', true);
      })
      .addCase(getProductCategory.pending, (state) => {
        set(state, 'category.loading', true);
      })
      .addCase(getProductCategory.fulfilled, (state, action) => {
        set(state, 'category.loading', false);
        set(state, 'category.data', action.payload);
      })
      .addCase(getProductCategory.rejected, (state) => {
        set(state, 'category.loading', false);
        set(state, 'category.error', true);
      });
  },
});

export default productSlice.reducer;
