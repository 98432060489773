/* eslint-disable import/no-unresolved */
import 'swiper/scss';
import 'swiper/scss/pagination';

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { A11y, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

const CarouselOnboardingWrapper = styled((props) => <Swiper {...props} />)`
  display: flex;
  flex-direction: column-reverse;
  .swiper-pagination {
    position: relative;
    top: -164px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 100%;
    height: 44px;
    margin-left: auto;
    margin-right: auto;
    &::before {
      content: '';
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      width: 100%;
      height: 20px;
      background-color: white;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    .swiper-pagination-bullet {
      margin: 0 0.375rem;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #a988cc;
      opacity: 0.5;
      &.swiper-pagination-bullet-active {
        background-color: #a988cc;
        opacity: 1;
      }
    }
  }
`;

function CarouselOnboarding({ children, onSwiperRef, onSlideChangeRef }) {
  const pagination = {
    clickable: true,
  };

  return (
    <CarouselOnboardingWrapper
      className="overflow-visible"
      modules={[Pagination, A11y]}
      slidesPerView={1}
      pagination={pagination}
      onSlideChange={onSlideChangeRef}
      onSwiper={onSwiperRef}
    >
      {children}
    </CarouselOnboardingWrapper>
  );
}

CarouselOnboarding.propTypes = {
  children: PropTypes.node.isRequired,
  onSwiperRef: PropTypes.func.isRequired,
  onSlideChangeRef: PropTypes.func.isRequired,
};

export default React.memo(CarouselOnboarding);
