import 'react-modern-drawer/dist/index.css';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import Drawer from 'react-modern-drawer';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { deleteUserAddress, setDefaultAddress } from '../../features/user/userSlice';

function ItemAddress({ id, title, name, phone, desc, isPrimary, onSuccessSetDefault }) {
  const location = useLocation();
  const queryForm = new URLSearchParams(location.search).get('from');
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const setDefaultAddressAction = async () => {
    const resultDefaultAddress = await dispatch(setDefaultAddress({ id })).unwrap();
    if (resultDefaultAddress) {
      setIsOpen(false);
      onSuccessSetDefault();
    }
  };

  const deleteAddress = async () => {
    await dispatch(deleteUserAddress({ id })).unwrap();
    toast.success('Delete user address success');
    setIsOpen(false);
    onSuccessSetDefault();
  };

  return (
    <>
      <div className={`block rounded-md bg-white-gray border mb-4 ${isPrimary && 'bg-primary'}`} key={id}>
        <div onClick={toggleDrawer} aria-hidden="true" className="cursor-pointer">
          {/* <LazyLoadImage */}
          {/*  src={`${process.env.PUBLIC_URL}/assets/images/maps.jpg`} */}
          {/*  className="w-full h-[105px] object-cover object-center overflow-hidden rounded-tl-md rounded-tr-md" */}
          {/*  alt="map" */}
          {/* /> */}
        </div>
        <div className="relative p-3">
          <h3
            onClick={async () => {
              if (queryForm && queryForm === 'shipping') {
                await setDefaultAddressAction();
                history.push('/shipping');
                return;
              }
              toggleDrawer();
            }}
            aria-hidden="true"
            className={`flex justify-between items-center font-body text-base font-semibold text-dark leading-none cursor-pointer mb-0 ${
              isPrimary && 'text-white-gray'
            }`}
          >
            {title}
            {isPrimary && (
              <span className="font-body font-normal text-tiny py-0.5 px-2 bg-primary text-white rounded ml-1">
                Primary
              </span>
            )}
          </h3>
          <span className={`font-body font-normal text-xs text-dark ${isPrimary && 'text-white-gray'}`}>
            {name} - {phone}
          </span>
          <p
            className={`font-body font-normal text-xs text-dark/60 pr-8 overflow-hidden whitespace-nowrap text-ellipsis ${
              isPrimary && 'text-white-gray/80'
            }`}
          >
            {desc}
          </p>
          <button
            type="button"
            className="absolute z-40 right-4 bottom-4 flex justify-center items-center w-6 h-6 outline-none hover:outline-none shadow-none"
            onClick={toggleDrawer}
          >
            <svg
              className={`${isPrimary ? 'fill-white-gray' : 'fill-[#463557]'}`}
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 0H10V4H6V0ZM12 0H16V4H12V0ZM0 0H4V4H0V0Z" />
            </svg>
          </button>
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        className="bg-white shadow-sm pt-10 pb-4 rounded-tr-lg rounded-tl-lg before:content-[''] before:absolute before:top-4 before:left-0 before:right-0 before:h-1 before:w-10 before:bg-[#DDDDDD] before:block before:m-auto before:rounded-xl"
        size="auto"
        overlayOpacity={1}
        overlayColor="#ffffff40"
      >
        {!isPrimary && (
          <p
            role="presentation"
            onClick={setDefaultAddressAction}
            className="cursor-pointer font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557BF] font-semibold"
          >
            <span>Set as Primary</span>
          </p>
        )}
        <p
          role="presentation"
          onClick={() => {
            history.push(`/shipping/address/create?id=${id}`);
          }}
          className="cursor-pointer font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557BF] font-semibold"
        >
          <span>Change Address</span>
        </p>
        {!isPrimary && (
          <p
            role="presentation"
            onClick={deleteAddress}
            className="cursor-pointer font-body text-sm flex justify-between items-center py-2 px-6 text-[#463557BF] font-semibold"
          >
            <span>Delete Address</span>
          </p>
        )}
      </Drawer>
    </>
  );
}

ItemAddress.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  isPrimary: PropTypes.oneOfType([PropTypes.bool]),
};

ItemAddress.defaultProps = {
  isPrimary: false,
};

export default React.memo(ItemAddress);
