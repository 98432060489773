import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { AiOutlineHistory } from 'react-icons/ai';
import { HiOutlineHome, HiOutlineSearch, HiOutlineUserCircle, HiViewGrid } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';

const menuVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
    },
  },
  exit: {
    opacity: 0,
    y: 100,
  },
};

function MenuButton() {
  const [menuToggle, setMenuToggle] = useState(false);

  const handleMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };

  const wrapperClass =
    'fixed max-w-[calc(425px_-_48px)] mx-auto z-20 bottom-6 left-6 px-6 right-6 m-auto transition-all duration-500 ease-in-out after:content-[""] after:w-full after:h-full after:absolute after:left-0 after:right-0 after:top-0 after:bottom-0 after:bg-white after:shadow-sm after:transition-all after:ease-in-out after:duration-300 after:rounded-lg after:z-0';
  const navLinkClass =
    'text-2xl text-[#A988CC] flex-1 flex items-center justify-center opacity-0 transition-all duration-500 ease-in-out';
  const btnLinkClass =
    'relative z-20 w-14 h-14 flex items-center justify-center rounded-full m-auto text-white text-3xl bg-primary hover:bg-[#8B69AE] focus:bg-[#8B69AE] active:bg-[#8B69AE]/90 shadow-sm transition-all duration-500 ease-in-out outline-none focus:ring-0';

  return (
    <motion.div
      className={cx(`${wrapperClass} after:scale-x-0`, {
        'after:!scale-x-100': menuToggle,
      })}
      variants={menuVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {process.env.REACT_APP_ENABLE_ECOMMERCE === 'yes' ? (
        <button
          type="button"
          className={cx(`${btnLinkClass} scale-110 rotate-0`, {
            '!scale-100 !rotate-90': menuToggle,
          })}
          onClick={handleMenuToggle}
          tour="step-5"
        >
          <HiViewGrid />
        </button>
      ) : null}

      <div className="absolute z-10 left-0 right-0 top-0 bottom-0 m-auto flex items-center justify-between">
        <NavLink
          to="/landing"
          className={cx(`${navLinkClass} translate-x-[130px]`, {
            '!translate-x-0 !opacity-100': menuToggle,
          })}
          activeClassName="active"
        >
          <HiOutlineHome />
        </NavLink>
        <NavLink
          to="/products"
          className={cx(`${navLinkClass} translate-x-[65px]`, {
            '!translate-x-0 !opacity-100': menuToggle,
          })}
          activeClassName="active"
        >
          <HiOutlineSearch />
        </NavLink>
        <span className="block w-16 min-w-16" />
        <NavLink
          to="/transaction"
          className={cx(`${navLinkClass} -translate-x-[65px]`, {
            '!translate-x-0 !opacity-100': menuToggle,
          })}
          activeClassName="active"
        >
          <AiOutlineHistory />
        </NavLink>
        <NavLink
          to="/profile/personal"
          className={cx(`${navLinkClass} -translate-x-[130px]`, {
            '!translate-x-0 !opacity-100': menuToggle,
          })}
          activeClassName="active"
        >
          <HiOutlineUserCircle />
        </NavLink>
      </div>
    </motion.div>
  );
}

export default React.memo(MenuButton);
