/* eslint-disable */
import axiosApiInstance from '../../utils/api';
import { apiHeaderConfig } from '../../utils/helpers';

const API_URL_PRODUCT = '/api/v1/products';
const get = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${API_URL_PRODUCT}${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const API_URL_PRODUCT_CATEGORIES = '/api/v1/categories';
const getCategories = async (query = '') => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${API_URL_PRODUCT_CATEGORIES}${query}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const API_URL_PRODUCT_STOCK = '/api/v1/products/stock/';
const getStockProductService = async (query) => {
  const response = await axiosApiInstance.get(
    `${process.env.REACT_APP_API_HOST}${API_URL_PRODUCT_STOCK}${query.id}`,
    apiHeaderConfig(),
  );
  return response.data.data;
};

const ProductService = {
  get,
  getCategories,
  getStockProductService
};
export default ProductService;
